import React from "react";
import { Heading } from "../nav/Heading";
import { Hero } from "./hero/Hero";
import styles from "./home.module.scss";
import { About } from "./about/About";
import { Events } from "./events/Events";
import { Contact } from "./contact/Contact";

export const Home = () => {
  return (
    <>
      <div className={styles.home}>
        <Heading />
        <main className={styles.mainContent}>
          <Hero />
          <About />
          <Events />
          <Contact />
          <div
            style={{
              height: "200px",
              background:
                "linear-gradient(180deg, var(--background), var(--background-dark))",
            }}
          />
        </main>
      </div>
    </>
  );
};
