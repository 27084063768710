import { MyLinks } from "components/home/hero/components/MyLinks";
import { Reveal } from "components/utils/Reveal";
import { SectionHeader } from "components/utils/SectionHeader";
import styles from "./about.module.scss";
import { AiOutlineArrowRight } from "react-icons/ai";

export const About = () => {
  return (
    <section id="about" className="section-wrapper">
      <SectionHeader title="About" dir="l" />
      <div className={styles.about}>
        <div>
          <Reveal>
            <p className={styles.aboutText}>
            Welcome to herAInetwork, a vibrant community dedicated to empowering women in artificial intelligence. Founded by passionate women at <a className={styles.inlineLink} target="_blank" href="https://instalily.ai">Instalily AI</a>, we aim to create a supportive environment where women can share knowledge, foster collaboration, and inspire one another in the rapidly evolving field of AI.</p>
          </Reveal>
          <Reveal>
            <p className={styles.aboutText}>
            Our mission is to provide resources, mentorship, and networking opportunities that enhance the experience of women in technology and AI. Whether you're a seasoned professional or just starting your journey in AI, herAInetwork is here to connect you with like-minded individuals who share your passion for innovation and learning.</p>
          </Reveal>
          <Reveal>
            <p className={styles.aboutText}>
            Join us in building a strong network of women who are shaping the future of AI. Together, we can cultivate a culture of inclusivity, creativity, and excellence. Let’s collaborate, support each other, and amplify our voices in the world of AI!</p>
          </Reveal>
          <Reveal>
            <div className={styles.links}>
              <div className={styles.linksText}>
                <span>Join the Network</span>
                <AiOutlineArrowRight />
              </div>
              <MyLinks />
            </div>
          </Reveal>
        </div>
      </div>
    </section>
  );
};
