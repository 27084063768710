import { useState } from "react";
import { Reveal } from "components/utils/Reveal";
import styles from "./contact.module.scss";

export const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [jobTitle, setJobTitle] = useState(""); 
  const [company, setCompany] = useState(""); 
  const [location, setLocation] = useState(""); 
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(""); // State for error messages

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate input fields
    if (!name) {
      setError("Please add your name.");
      return;
    }
    if (!email) {
      setError("Please add your email.");
      return;
    }
    if (!jobTitle) {
      setError("Please add your job title.");
      return;
    }
    if (!company) {
      setError("Please add your company.");
      return;
    }
    if (!location) {
      setError("Please add your location.");
      return;
    }

    // Clear any previous error messages
    setError("");

    // Prepare data for submission
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("jobTitle", jobTitle); 
    formData.append("company", company); 
    formData.append("location", location); 

    console.log("Submitting")
    try {
      const response = await fetch("https://script.google.com/macros/s/AKfycbwOCbl0SZi4JBfiluSHrE3Gw9DUMttcx1-LVlf8E_0sWX3HouhP2R04UyqTUaYVayW9-w/exec", {
        method: "POST",
        body: formData,
        mode: "cors", 
        referrerPolicy: "no-referrer-when-downgrade"
      });

      if (response.ok) {
        // Clear form fields
        setName("");
        setEmail("");
        setJobTitle("");
        setCompany("");
        setLocation("");
        setSubmitted(true);
      } else {
        console.log("Error submitting form", response);
      }
    } catch (error) {
      console.log("Fetch error:", error);
    }
  };

  return (
    <section className="section-wrapper" id="contact">
      <div className={styles.contactWrapper}>
        <Reveal width="100%">
          <h4 className={styles.contactTitle}>
            Get Involved<span>.</span>
          </h4>
        </Reveal>
        <Reveal width="100%">
          <p className={styles.contactCopy}>
            Join our community of women in AI. Together, we are building a network
            of support, knowledge sharing, and collaboration. Whether you're 
            a seasoned professional or just starting your journey in AI, 
            we welcome you to connect with like-minded individuals and 
            help shape the future of this exciting field.
          </p>
        </Reveal>
        <Reveal width="100%">
          {!submitted ? (
            <form onSubmit={handleSubmit} className={styles.contactForm}>
              <input
                type="text"
                placeholder="Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={styles.inputField}
                required
              />
              <input
                type="email"
                placeholder="Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={styles.inputField}
                required
              />
              <input
                type="text"
                placeholder="Your Job Title"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                className={styles.inputField}
                required
              />
              <input
                type="text"
                placeholder="Your Company"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                className={styles.inputField}
                required
              />
              <input
                type="text"
                placeholder="Your Location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                className={styles.inputField}
                required
              />
              {error && <p className={styles.errorMessage}>{error}</p>} 
              <button type="submit" className={styles.submitButton}>
                Submit
              </button>
            </form>
          ) : (
            <p className={styles.thankYouMessage}>
              Thank you, we'll get in touch with you soon.
            </p>
          )}
        </Reveal>
      </div>
    </section>
  );
};
