//import ParticleCircle from "testing/circle2";
import GlitchImage from "testing/GlitchImage"
import 'App.css'
import Head from "next/head";
import { Home } from "components/home/Home";

function App() {
  return (
    <>
      <Head>
        <title>Her AI Network</title>
        <meta name="description" content="Bleep boop" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap" rel="stylesheet"/>
      </Head>
      <Home />
    </>
  );
}

export default App;
