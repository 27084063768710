import { Reveal } from "components/utils/Reveal";
import { useAnimation, useInView, motion } from "framer-motion";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import { AiOutlineExport } from "react-icons/ai";
import styles from "./events.module.scss";

export const Event = ({
  modalContent,
  eventLink,
  description,
  imgSrc,
  title,
}) => {
  const [hovered, setHovered] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const controls = useAnimation();

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [isInView, controls]);

  return (
    <>
      <motion.div
        ref={ref}
        variants={{
          hidden: { opacity: 0, y: 100 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={controls}
        transition={{ duration: 0.75 }}
      >
        <div
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={() => setIsOpen(true)}
          className={styles.eventImage}
        >
          <img
            src={imgSrc}
            style={{
              width: hovered ? "90%" : "85%",
              rotate: hovered ? "2deg" : "0deg",
            }}
          />
        </div>
        <div className={styles.eventCopy}>
          <Reveal width="100%">
            <div className={styles.eventTitle}>
              <h4>{title}</h4>
              <div className={styles.eventTitleLine} />

              <Link href={eventLink} target="_blank" rel="nofollow">
                <AiOutlineExport size="2.8rem" />
              </Link>
            </div>
          </Reveal>
          <Reveal>
            <p className={styles.eventDescription}>
              {description}{" "}
              {/* <span onClick={() => setIsOpen(true)}>Learn more {">"}</span> */}
            </p>
          </Reveal>
        </div>
      </motion.div>
    </>
  );
};
