import { SectionHeader } from "components/utils/SectionHeader";
import { Event } from "./Event";
import styles from "./events.module.scss";

export const Events = () => {
  return (
    <section className="section-wrapper" id="events">
      <SectionHeader title="Events" dir="r" />

      <div className={styles.events}>
        {events.map((event) => {
          return <Event key={event.title} {...event} />;
        })}
      </div>
    </section>
  );
};

const events = [
  {
    title: "Women in AI Breakfast & Coffee at SF Tech Week",
    date: 'October 11th, 2024',
    imgSrc: "/events/sf.png",
    eventLink: "https://lu.ma/gymg6nmr",
    description:
      "Women in AI Breakfast and Coffee with 5 incredible panelists coming to SF Tech Week!",
    modalContent: (
      <>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aspernatur
          quia officia odio nulla consectetur aperiam ad tempora magni magnam
          nesciunt.
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aspernatur
          quia officia odio nulla consectetur aperiam ad tempora magni magnam
          nesciunt.
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aspernatur
          quia officia odio nulla consectetur aperiam ad tempora magni magnam
          nesciunt.
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aspernatur
          quia officia odio nulla consectetur aperiam ad tempora magni magnam
          nesciunt.
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aspernatur
          quia officia odio nulla consectetur aperiam ad tempora magni magnam
          nesciunt.
        </p>
      </>
    ),
  },
  {
    title: "Women in AI Breakfast & Coffee at NY Tech Week",
    date: 'June 7th, 2024',
    imgSrc: "/events/ny.jpeg",
    eventLink: "https://lu.ma/l0x2eq81",
    description:
      "Instalily was thrilled to host Women In AI Breakfast & Coffee on Friday, June 7th!",
    modalContent: (
      <>
        <p>
          As a part of New York Tech Week, Instalily was thrilled to host Women In AI Breakfast & Coffee on Friday, June 7th! We were excited to welcome women in the AI space who were revolutionizing the field of artificial intelligence and bringing fresh perspectives and innovative solutions to some of the industry's most pressing challenges. We had a full capacity turnout, and it was inspiring to see so many passionate and talented individuals come together to celebrate and discuss the rapidly changing AI ecosystem.
        </p>
        <p>
        Our panelists led a fantastic conversation about the pivotal roles women have played and continue to play in shaping the future of AI. From groundbreaking research to innovative applications, women are driving significant advancements and setting new standards in the industry.
        </p>
        <p>
        Following the tremendous success of the event, Instalily aspires to continue advancing the Women in AI initiative. We hope to organize virtual communities on platforms such as Slack and Discord to bring together women from all areas of the AI domain. We also plan to host in-person events to foster a dynamic community where members can share industry insights and offer mutual support.
        </p>
        <p>
        The journey doesn't end here – it's just beginning!
        </p>
      </>
    ),
  },
];
