import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { ShaderMaterial } from 'three';
import planet from 'assets/planet.png'; // Replace with your image path

const GlitchImage = () => {
  const mountRef = useRef(null);
  const imageURL = planet;

  useEffect(() => {
    let frameId;
    const width = mountRef.current.clientWidth;
    const height = mountRef.current.clientHeight;

    // Create the scene
    const scene = new THREE.Scene();

    // Create the camera with even more space around the center for movement
    const camera = new THREE.OrthographicCamera(
      width / -1.2,
      width / 1.2,
      height / 1.2,
      height / -1.2,
      1,
      1000
    );
    camera.position.z = 1;

    // Create the renderer with transparency enabled
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(width, height);
    renderer.setClearColor(0x000000, 0);
    mountRef.current.appendChild(renderer.domElement);

    // Load texture (image) with transparency support
    const textureLoader = new THREE.TextureLoader();
    const texture = textureLoader.load(imageURL, (tex) => {
      tex.encoding = THREE.RGBAFormat;
      tex.magFilter = THREE.LinearFilter;
      tex.minFilter = THREE.LinearMipMapLinearFilter;
      tex.wrapS = THREE.RepeatWrapping;
      tex.wrapT = THREE.RepeatWrapping;
      tex.repeat.set(1.2, 1.2); // Slightly larger texture for edge blending
      tex.format = THREE.RGBAFormat;
    });

    // Create a circular geometry (planet shape) with a slightly larger size
    const circleRadius = Math.min(width, height) / 1.5;
    const geometry = new THREE.CircleGeometry(circleRadius, 70);

    // Shader material
    const shaderMaterial = new ShaderMaterial({
      uniforms: {
        u_time: { value: 0.0 },
        u_mouse: { value: new THREE.Vector2() },
        u_texture: { value: texture },
        u_bgColor: { value: new THREE.Color('var(--background)') },
        u_resolution: { value: new THREE.Vector2(width, height) },
      },
      vertexShader: vertexShader(),
      fragmentShader: fragmentShader(),
      transparent: true
    });

    // Create the circle mesh with the texture
    const circle = new THREE.Mesh(geometry, shaderMaterial);
    scene.add(circle);

    // Mouse move event to pass coordinates to the shader
    const handleMouseMove = (event) => {
      const mouseX = (event.clientX / width) * 2 - 1;
      const mouseY = -(event.clientY / height) * 2 + 1;
      shaderMaterial.uniforms.u_mouse.value.set(mouseX, mouseY);
    };

    // Animate the shader
    const animate = () => {
      shaderMaterial.uniforms.u_time.value += 0.04;
      renderer.render(scene, camera);
      frameId = requestAnimationFrame(animate);
    };

    // Start animation
    animate();

    // Add mousemove listener for interaction
    window.addEventListener('mousemove', handleMouseMove);

    // Cleanup
    return () => {
      cancelAnimationFrame(frameId);
      window.removeEventListener('mousemove', handleMouseMove);
      mountRef.current.removeChild(renderer.domElement);
    };
  }, [imageURL]);

  return <div ref={mountRef} style={{ width: '100%', height: '100vh', background: 'var(--background)' }} />;
};

export default GlitchImage;

// Vertex shader (passes position and UV to fragment shader)
const vertexShader = () => `
  varying vec2 v_uv;
  void main() {
    v_uv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

// Fragment shader (handles glitch effect and background blending)
const fragmentShader = () => `
  uniform float u_time;
  uniform vec2 u_mouse;
  uniform vec2 u_resolution;
  uniform sampler2D u_texture;
  uniform vec3 u_bgColor;
  varying vec2 v_uv;

  // Random noise generator
  float random(vec2 co) {
    return fract(sin(dot(co.xy, vec2(12.9898, 78.233))) * 43758.5453);
  }

  // 2D Noise based on Morgan McGuire's implementation
  float noise(in vec2 st) {
    vec2 i = floor(st);
    vec2 f = fract(st);

    float a = random(i);
    float b = random(i + vec2(1.0, 0.0));
    float c = random(i + vec2(0.0, 1.0));
    float d = random(i + vec2(1.0, 1.0));

    vec2 u = f * f * (3.0 - 2.0 * f);

    return mix(a, b, u.x) +
            (c - a)* u.y * (1.0 - u.x) +
            (d - b) * u.x * u.y;
  }
  
  void main() {
    vec2 uv = v_uv;
    vec2 mouseInfluence = (u_mouse + 1.0) * 0.5; // Normalize mouse coordinates
  
    // Time-based glitching with reduced intensity
    float glitchAmount = noise(uv * 10.0 + u_time * 0.1) * 0.03;
    vec2 glitchShift = vec2(
      glitchAmount * sin(u_time * 2.0 + uv.y * 20.0),
      glitchAmount * cos(u_time * 2.0 + uv.x * 20.0)
    );
  
    // Apply displacement based on time, mouse position, and noise
    vec2 displacedUV = uv + glitchShift * (mouseInfluence * 2.0 - 1.0);
  
    // Add some wobble to the edges (reduced intensity)
    float edgeWobble = sin(u_time * 3.0 + uv.x * 10.0 + uv.y * 10.0) * 0.005; 
    displacedUV += edgeWobble;
  
    // Sample the texture with displacement
    vec4 color = texture2D(u_texture, displacedUV);
  
    // Add glitch effect with color shifts (reduced intensity)
    float glitchIntensity = step(0.98, random(uv * u_time)); 
    vec3 glitchColor = color.rgb + glitchIntensity * vec3(0.1, 0.25, 0.45) * glitchAmount * 5.0;
  
    // Smooth circle mask
    vec2 center = vec2(0.5, 0.5);
    float radius = 0.5;
    float smoothEdge = 0.01; 
    float dist = distance(uv, center);
    float circleMask = 1.0 - smoothstep(radius - smoothEdge, radius + smoothEdge, dist);
  
    // Apply the circle mask and blend with background
    vec4 finalColor = vec4(glitchColor, color.a * circleMask * 0.4); 
    
    // Increase background blending
    float blendFactor = smoothstep(radius - smoothEdge, radius + smoothEdge, dist);
    finalColor.rgb = mix(finalColor.rgb, u_bgColor, blendFactor);
    finalColor.a = mix(finalColor.a, 0.0, blendFactor);
  
    gl_FragColor = finalColor;
  }  
`;